<template>
  <div class="wrapper new-version" ref="page">
    <ctheader></ctheader>
    <div class="content">
      <div class="form">
        <div class="sub-title">基本信息</div>
        <div class="field">
          <label>版本名称</label>
          <input type="text" v-model="name" disabled />
        </div>
        <div class="field">
          <label>版本描述</label>
          <textarea
            name=""
            id=""
            cols="30"
            rows="10"
            v-model="desc"
            disabled
          ></textarea>
        </div>
        <div class="field">
          <label>权限类型</label>
          <select class="w300" v-model="type">
            <option v-for="row in typeList" :value="row.id" :key="row.id">{{
              row.name
            }}</option>
          </select>
        </div>
        <div class="sub-title">
          <span>权限设置</span>
        </div>
      </div>
      <!-- 市场端 -->
      <div class="limit1">市场端权限</div>
      <div class="role-right-grid">
        <div class="system">
          <div class="system-content">
            <div class="menu-item" v-for="(menu1, index1) in menus">
              <div
                class="menu-item-level-1"
                :class="{ 'no-level-3': menu1.menus.length === 0 }"
              >
                <i
                  class="sn-icon disabled"
                  :class="menu1.icon"
                  data-level="1"
                ></i>
                <span class="menu-item1 mgl-5">{{ menu1.title }}</span>
              </div>
              <template v-if="menu1.menus.length > 0 && !menu1.lastTwo">
                <div class="menu-item" v-for="(menu2, index2) in menu1.menus">
                  <div
                    class="menu-item-level-2"
                    :class="{ 'no-level-3': menu2.menus.length === 0 }"
                  >
                    <i
                      class="sn-icon disabled"
                      :class="menu2.icon"
                      data-level="2"
                    ></i>
                    <span class="menu-item1 mgl-5">{{ menu2.title }}</span>
                  </div>
                  <!-- <div class="menu-item-level-2" :class="{'no-level-3': menu2.menus.length === 0}" v-else>
									<i class="sn-icon disabled"
										:class="menu2.icon"
										@click="chooseEle2(index1, index2)"
										data-level="2"
										></i>
									<span class="menu-item1 mgl-5">{{menu2.title}}</span>
								</div> -->
                  <template v-if="menu2.menus.length > 0 && !menu2.lastTwo">
                    <div
                      class="menu-item"
                      v-for="(menu3, index3) in menu2.menus"
                    >
                      <div
                        class="menu-item-level-3"
                        :class="{ 'no-level-3': menu3.menus.length === 0 }"
                        v-if="!menu3.element"
                      >
                        <i
                          class="sn-icon disabled"
                          :class="menu3.icon"
                          data-level="3"
                        ></i>
                        <span class="menu-item1 mgl-5">{{ menu3.title }}</span>
                      </div>
                      <div
                        class="menu-item-level-3"
                        :class="{ 'no-level-3': menu3.menus.length === 0 }"
                        v-else
                      >
                        <i
                          class="sn-icon disabled"
                          :class="menu3.icon"
                          data-level="3"
                        ></i>
                        <span class="menu-item1 mgl-5">{{ menu3.title }}</span>
                      </div>
                      <template v-if="menu3.menus.length > 0 && !menu3.lastTwo">
                        <div
                          class="menu-item"
                          v-for="(menu4, index4) in menu3.menus"
                        >
                          <div
                            class="menu-item-level-4"
                            :class="{ 'no-level-3': menu4.menus.length === 0 }"
                          >
                            <i
                              class="sn-icon disabled"
                              :class="menu4.icon"
                              data-level="4"
                            ></i>
                            <span class="menu-item1 mgl-5">{{
                              menu4.title
                            }}</span>
                          </div>
                          <template
                            v-if="menu4.menus.length > 0 && !menu4.lastTwo"
                          >
                            <div
                              class="menu-item"
                              v-for="(menu5, index5) in menu4.menus"
                            >
                              <div
                                class="menu-item-level-5 no-level-3"
                                :class="{
                                  'no-level-3': menu5.menus.length === 0,
                                }"
                              >
                                <i
                                  class="sn-icon disabled"
                                  :class="menu5.icon"
                                  data-level="5"
                                ></i>
                                <span class="menu-item1 mgl-5">{{
                                  menu5.title
                                }}</span>
                              </div>
                            </div>
                          </template>
                          <div class="menu-item" v-if="menu4.lastTwo">
                            <div class="menu-item-level-5 no-level-3">
                              <template v-for="(menu5, index5) in menu4.menus">
                                <i
                                  class="sn-icon disabled"
                                  :class="menu5.icon"
                                  data-level="5"
                                ></i>
                                <span class="menu-item3 mgl-5 mgr-20">{{
                                  menu5.title
                                }}</span>
                              </template>
                            </div>
                          </div>
                        </div>
                      </template>
                      <div
                        class="menu-item"
                        v-if="menu3.menus.length > 0 && menu3.lastTwo"
                      >
                        <div class="menu-item-level-4 no-level-3">
                          <template v-for="(menu4, index4) in menu3.menus">
                            <i
                              class="sn-icon disabled"
                              :class="menu4.icon"
                              data-level="4"
                            ></i>
                            <span class="menu-item3 mgl-5 mgr-20">{{
                              menu4.title
                            }}</span>
                          </template>
                        </div>
                        <!-- <div class="menu-item-level-4 no-level-3" v-else>
												<template v-for="(menu4, index4) in menu3.menus">
													<i class="sn-icon disabled"
														:class="menu4.icon"
														data-level="4"
														@click="chooseMenu4(index1, index2, index3, index4)"
														></i>
													<span class="menu-item3 mgl-5 mgr-20">{{menu4.title}}</span>
												</template>
											</div> -->
                      </div>
                    </div>
                  </template>
                  <div
                    class="menu-item"
                    v-if="menu2.menus.length > 0 && menu2.lastTwo"
                  >
                    <div class="menu-item-level-3 no-level-3">
                      <template v-for="(menu3, index3) in menu2.menus">
                        <i
                          class="sn-icon disabled"
                          :class="menu3.icon"
                          data-level="3"
                        ></i>
                        <span class="menu-item3 mgl-5 mgr-20">{{
                          menu3.title
                        }}</span>
                      </template>
                    </div>
                    <!-- <div class="menu-item-level-3 no-level-3" v-else>
										<template v-for="(menu3, index3) in menu2.menus">
											<i class="sn-icon disabled"
												:class="menu3.icon"
												data-level="3"
												@click="chooseMenu3(index1, index2, index3)"
												></i>
											<span class="menu-item3 mgl-5 mgr-20">{{menu3.title}}</span>
										</template>
									</div> -->
                  </div>
                </div>
              </template>
              <div
                class="menu-item"
                v-if="menu1.menus.length > 0 && menu1.lastTwo"
              >
                <div class="menu-item-level-2 no-level-3">
                  <template v-for="(menu2, index2) in menu1.menus">
                    <i
                      class="sn-icon disabled"
                      :class="menu2.icon"
                      data-level="2"
                    ></i>
                    <span class="menu-item3 mgl-5 mgr-20">{{
                      menu2.title
                    }}</span>
                  </template>
                </div>
                <!-- <div class="menu-item-level-2 no-level-3" v-else>
								<template v-for="(menu2, index2) in menu1.menus">
									<i class="sn-icon disabled"
										:class="menu2.icon"
										data-level="2"
										@click="chooseMenu2(index1, index2)"
										></i>
									<span class="menu-item3 mgl-5 mgr-20">{{menu2.title}}</span>
								</template>
							</div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 公司端 -->
      <div>
        <div class="limit1">公司端权限</div>
        <div class="role-right-grid">
          <div class="system">
            <div class="system-content">
              <div
                class="menu-item"
                v-for="(menu1, index1) in company_menus"
                :key="index1"
              >
                <div
                  class="menu-item-level-1"
                  :class="{ 'no-level-3': menu1.menus.length === 0 }"
                >
                  <i
                    class="sn-icon disabled"
                    :class="menu1.icon"
                    @click="chooseMainMenu(index1, 2)"
                    data-level="1"
                  ></i>
                  <span class="menu-item1 mgl-5">{{ menu1.title }}</span>
                </div>
                <template v-if="menu1.menus.length > 0 && !menu1.lastTwo">
                  <div
                    class="menu-item"
                    v-for="(menu2, index2) in menu1.menus"
                    :key="index2"
                  >
                    <div
                      class="menu-item-level-2"
                      :class="{ 'no-level-3': menu2.menus.length === 0 }"
                      v-if="!menu2.element"
                    >
                      <i
                        class="sn-icon disabled"
                        :class="menu2.icon"
                        @click="chooseMenu2(index1, index2, 2)"
                        data-level="2"
                      ></i>
                      <span class="menu-item1 mgl-5">{{ menu2.title }}</span>
                    </div>
                    <div
                      class="menu-item-level-2"
                      :class="{ 'no-level-3': menu2.menus.length === 0 }"
                      v-else
                    >
                      <i
                        class="sn-icon disabled"
                        :class="menu2.icon"
                        @click="chooseEle2(index1, index2, 2)"
                        data-level="2"
                      ></i>
                      <span class="menu-item3 mgl-5">{{ menu2.title }}</span>
                    </div>
                    <template v-if="menu2.menus.length > 0 && !menu2.lastTwo">
                      <div
                        class="menu-item"
                        v-for="(menu3, index3) in menu2.menus"
                        :key="index3"
                      >
                        <div
                          class="menu-item-level-3"
                          :class="{ 'no-level-3': menu3.menus.length === 0 }"
                          v-if="!menu3.element"
                        >
                          <i
                            class="sn-icon disabled"
                            :class="menu3.icon"
                            @click="chooseMenu3(index1, index2, index3, 2)"
                            data-level="3"
                          ></i>
                          <span class="menu-item1 mgl-5">{{
                            menu3.title
                          }}</span>
                        </div>
                        <div
                          class="menu-item-level-3"
                          :class="{ 'no-level-3': menu3.menus.length === 0 }"
                          v-else
                        >
                          <i
                            class="sn-icon disabled"
                            :class="menu3.icon"
                            @click="chooseEle3(index1, index2, index3, 2)"
                            data-level="3"
                          ></i>
                          <span class="menu-item3 mgl-5">{{
                            menu3.title
                          }}</span>
                        </div>
                        <template
                          v-if="menu3.menus.length > 0 && !menu3.lastTwo"
                        >
                          <div
                            class="menu-item"
                            v-for="(menu4, index4) in menu3.menus"
                            :key="index4"
                          >
                            <div
                              class="menu-item-level-4"
                              :class="{
                                'no-level-3': menu4.menus.length === 0,
                              }"
                            >
                              <i
                                class="sn-icon disabled"
                                :class="menu4.icon"
                                @click="
                                  chooseEle4(index1, index2, index3, index4, 2)
                                "
                                data-level="4"
                              ></i>
                              <span class="menu-item3 mgl-5">{{
                                menu4.title
                              }}</span>
                            </div>
                            <template
                              v-if="menu4.menus.length > 0 && !menu4.lastTwo"
                            >
                              <div
                                class="menu-item"
                                v-for="(menu5, index5) in menu4.menus"
                                :key="index5"
                              >
                                <div
                                  class="menu-item-level-5 no-level-3"
                                  :class="{
                                    'no-level-3': menu5.menus.length === 0,
                                  }"
                                >
                                  <i
                                    class="sn-icon disabled"
                                    :class="menu5.icon"
                                    @click="
                                      chooseEle5(
                                        index1,
                                        index2,
                                        index3,
                                        index4,
                                        index5,
                                        2
                                      )
                                    "
                                    data-level="5"
                                  ></i>
                                  <span class="menu-item3 mgl-5">{{
                                    menu5.title
                                  }}</span>
                                </div>
                              </div>
                            </template>
                            <div class="menu-item" v-if="menu4.lastTwo">
                              <div class="menu-item-level-5 no-level-3">
                                <template
                                  v-for="(menu5, index5) in menu4.menus"
                                >
                                  <i
                                    class="sn-icon disabled"
                                    :class="menu5.icon"
                                    data-level="5"
                                    @click="
                                      chooseEle5(
                                        index1,
                                        index2,
                                        index3,
                                        index4,
                                        index5,
                                        2
                                      )
                                    "
                                  ></i>
                                  <span class="menu-item3 mgl-5 mgr-20">
                                    {{ menu5.title }}
                                  </span>
                                </template>
                              </div>
                            </div>
                          </div>
                        </template>
                        <div
                          class="menu-item"
                          v-if="menu3.menus.length > 0 && menu3.lastTwo"
                        >
                          <div
                            class="menu-item-level-4 no-level-3"
                            v-if="menu3.menus[0].element"
                          >
                            <template v-for="(menu4, index4) in menu3.menus">
                              <i
                                class="sn-icon disabled"
                                :class="menu4.icon"
                                data-level="4"
                                @click="
                                  chooseEle4(index1, index2, index3, index4, 2)
                                "
                              ></i>
                              <span class="menu-item3 mgl-5 mgr-20">{{
                                menu4.title
                              }}</span>
                            </template>
                          </div>
                          <div class="menu-item-level-4 no-level-3" v-else>
                            <template v-for="(menu4, index4) in menu3.menus">
                              <i
                                class="sn-icon disabled"
                                :class="menu4.icon"
                                data-level="4"
                                @click="
                                  chooseMenu4(index1, index2, index3, index4, 2)
                                "
                              ></i>
                              <span class="menu-item1 mgl-5 mgr-20">{{
                                menu4.title
                              }}</span>
                            </template>
                          </div>
                        </div>
                      </div>
                    </template>
                    <div
                      class="menu-item"
                      v-if="menu2.menus.length > 0 && menu2.lastTwo"
                    >
                      <div
                        class="menu-item-level-3 no-level-3"
                        v-if="menu2.menus[0].element"
                      >
                        <template v-for="(menu3, index3) in menu2.menus">
                          <i
                            class="sn-icon disabled"
                            :class="menu3.icon"
                            data-level="3"
                            @click="chooseEle3(index1, index2, index3, 2)"
                          ></i>
                          <span class="menu-item3 mgl-5 mgr-20">{{
                            menu3.title
                          }}</span>
                        </template>
                      </div>
                      <div class="menu-item-level-3 no-level-3" v-else>
                        <template v-for="(menu3, index3) in menu2.menus">
                          <i
                            class="sn-icon disabled"
                            :class="menu3.icon"
                            data-level="3"
                            @click="chooseMenu3(index1, index2, index3, 2)"
                          ></i>
                          <span class="menu-item1 mgl-5 mgr-20">{{
                            menu3.title
                          }}</span>
                        </template>
                      </div>
                    </div>
                  </div>
                </template>
                <div
                  class="menu-item"
                  v-if="menu1.menus.length > 0 && menu1.lastTwo"
                >
                  <div
                    class="menu-item-level-2 no-level-3"
                    v-if="menu1.menus[0].element"
                  >
                    <template v-for="(menu2, index2) in menu1.menus">
                      <i
                        class="sn-icon disabled"
                        :class="menu2.icon"
                        data-level="2"
                        @click="chooseEle2(index1, index2, 2)"
                      ></i>
                      <span class="menu-item3 mgl-5 mgr-20">{{
                        menu2.title
                      }}</span>
                    </template>
                  </div>
                  <div class="menu-item-level-2 no-level-3" v-else>
                    <template v-for="(menu2, index2) in menu1.menus">
                      <i
                        class="sn-icon disabled"
                        :class="menu2.icon"
                        data-level="2"
                        @click="chooseMenu2(index1, index2, 2)"
                      ></i>
                      <span class="menu-item1 mgl-5 mgr-20">{{
                        menu2.title
                      }}</span>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 市场助手 -->
      <div>
        <div class="limit1">市场助手权限</div>
        <div class="role-right-grid">
          <div class="system">
            <div class="system-content">
              <div
                class="menu-item"
                v-for="(menu1, index1) in market_menu"
                :key="index1"
              >
                <div
                  class="menu-item-level-1"
                  :class="{ 'no-level-3': menu1.menus.length === 0 }"
                >
                  <i
                    class="sn-icon disabled"
                    :class="menu1.icon"
                    @click="chooseMainMenu(index1, 3)"
                    data-level="1"
                  ></i>
                  <span class="menu-item1 mgl-5">{{ menu1.title }}</span>
                </div>
                <template v-if="menu1.menus.length > 0 && !menu1.lastTwo">
                  <div
                    class="menu-item"
                    v-for="(menu2, index2) in menu1.menus"
                    :key="index2"
                  >
                    <div
                      class="menu-item-level-2"
                      :class="{ 'no-level-3': menu2.menus.length === 0 }"
                      v-if="!menu2.element"
                    >
                      <i
                        class="sn-icon disabled"
                        :class="menu2.icon"
                        @click="chooseMenu2(index1, index2, 3)"
                        data-level="2"
                      ></i>
                      <span class="menu-item1 mgl-5">{{ menu2.title }}</span>
                    </div>
                    <div
                      class="menu-item-level-2"
                      :class="{ 'no-level-3': menu2.menus.length === 0 }"
                      v-else
                    >
                      <i
                        class="sn-icon disabled"
                        :class="menu2.icon"
                        @click="chooseEle2(index1, index2, 3)"
                        data-level="2"
                      ></i>
                      <span class="menu-item3 mgl-5">{{ menu2.title }}</span>
                    </div>
                    <template v-if="menu2.menus.length > 0 && !menu2.lastTwo">
                      <div
                        class="menu-item"
                        v-for="(menu3, index3) in menu2.menus"
                        :key="index3"
                      >
                        <div
                          class="menu-item-level-3"
                          :class="{ 'no-level-3': menu3.menus.length === 0 }"
                          v-if="!menu3.element"
                        >
                          <i
                            class="sn-icon disabled"
                            :class="menu3.icon"
                            @click="chooseMenu3(index1, index2, index3, 3)"
                            data-level="3"
                          ></i>
                          <span class="menu-item1 mgl-5">{{
                            menu3.title
                          }}</span>
                        </div>
                        <div
                          class="menu-item-level-3"
                          :class="{ 'no-level-3': menu3.menus.length === 0 }"
                          v-else
                        >
                          <i
                            class="sn-icon disabled"
                            :class="menu3.icon"
                            @click="chooseEle3(index1, index2, index3, 3)"
                            data-level="3"
                          ></i>
                          <span class="menu-item3 mgl-5">{{
                            menu3.title
                          }}</span>
                        </div>
                        <template
                          v-if="menu3.menus.length > 0 && !menu3.lastTwo"
                        >
                          <div
                            class="menu-item"
                            v-for="(menu4, index4) in menu3.menus"
                            :key="index4"
                          >
                            <div
                              class="menu-item-level-4"
                              :class="{
                                'no-level-3': menu4.menus.length === 0,
                              }"
                            >
                              <i
                                class="sn-icon disabled"
                                :class="menu4.icon"
                                @click="
                                  chooseEle4(index1, index2, index3, index4, 3)
                                "
                                data-level="4"
                              ></i>
                              <span class="menu-item3 mgl-5">{{
                                menu4.title
                              }}</span>
                            </div>
                            <template
                              v-if="menu4.menus.length > 0 && !menu4.lastTwo"
                            >
                              <div
                                class="menu-item"
                                v-for="(menu5, index5) in menu4.menus"
                                :key="index5"
                              >
                                <div
                                  class="menu-item-level-5 no-level-3"
                                  :class="{
                                    'no-level-3': menu5.menus.length === 0,
                                  }"
                                >
                                  <i
                                    class="sn-icon disabled"
                                    :class="menu5.icon"
                                    @click="
                                      chooseEle5(
                                        index1,
                                        index2,
                                        index3,
                                        index4,
                                        index5,
                                        3
                                      )
                                    "
                                    data-level="5"
                                  ></i>
                                  <span class="menu-item3 mgl-5">{{
                                    menu5.title
                                  }}</span>
                                </div>
                              </div>
                            </template>
                            <div class="menu-item" v-if="menu4.lastTwo">
                              <div class="menu-item-level-5 no-level-3">
                                <template
                                  v-for="(menu5, index5) in menu4.menus"
                                >
                                  <i
                                    class="sn-icon disabled"
                                    :class="menu5.icon"
                                    data-level="5"
                                    @click="
                                      chooseEle5(
                                        index1,
                                        index2,
                                        index3,
                                        index4,
                                        index5,
                                        3
                                      )
                                    "
                                  ></i>
                                  <span class="menu-item3 mgl-5 mgr-20">
                                    {{ menu5.title }}
                                  </span>
                                </template>
                              </div>
                            </div>
                          </div>
                        </template>
                        <div
                          class="menu-item"
                          v-if="menu3.menus.length > 0 && menu3.lastTwo"
                        >
                          <div
                            class="menu-item-level-4 no-level-3"
                            v-if="menu3.menus[0].element"
                          >
                            <template v-for="(menu4, index4) in menu3.menus">
                              <i
                                class="sn-icon disabled"
                                :class="menu4.icon"
                                data-level="4"
                                @click="
                                  chooseEle4(index1, index2, index3, index4, 3)
                                "
                              ></i>
                              <span class="menu-item3 mgl-5 mgr-20">{{
                                menu4.title
                              }}</span>
                            </template>
                          </div>
                          <div class="menu-item-level-4 no-level-3" v-else>
                            <template v-for="(menu4, index4) in menu3.menus">
                              <i
                                class="sn-icon disabled"
                                :class="menu4.icon"
                                data-level="4"
                                @click="
                                  chooseMenu4(index1, index2, index3, index4, 3)
                                "
                              ></i>
                              <span class="menu-item1 mgl-5 mgr-20">{{
                                menu4.title
                              }}</span>
                            </template>
                          </div>
                        </div>
                      </div>
                    </template>
                    <div
                      class="menu-item"
                      v-if="menu2.menus.length > 0 && menu2.lastTwo"
                    >
                      <div
                        class="menu-item-level-3 no-level-3"
                        v-if="menu2.menus[0].element"
                      >
                        <template v-for="(menu3, index3) in menu2.menus">
                          <i
                            class="sn-icon disabled"
                            :class="menu3.icon"
                            data-level="3"
                            @click="chooseEle3(index1, index2, index3, 3)"
                          ></i>
                          <span class="menu-item3 mgl-5 mgr-20">{{
                            menu3.title
                          }}</span>
                        </template>
                      </div>
                      <div class="menu-item-level-3 no-level-3" v-else>
                        <template v-for="(menu3, index3) in menu2.menus">
                          <i
                            class="sn-icon disabled"
                            :class="menu3.icon"
                            data-level="3"
                            @click="chooseMenu3(index1, index2, index3, 3)"
                          ></i>
                          <span class="menu-item1 mgl-5 mgr-20">{{
                            menu3.title
                          }}</span>
                        </template>
                      </div>
                    </div>
                  </div>
                </template>
                <div
                  class="menu-item"
                  v-if="menu1.menus.length > 0 && menu1.lastTwo"
                >
                  <div
                    class="menu-item-level-2 no-level-3"
                    v-if="menu1.menus[0].element"
                  >
                    <template v-for="(menu2, index2) in menu1.menus">
                      <i
                        class="sn-icon disabled"
                        :class="menu2.icon"
                        data-level="2"
                        @click="chooseEle2(index1, index2, 3)"
                      ></i>
                      <span class="menu-item3 mgl-5 mgr-20">{{
                        menu2.title
                      }}</span>
                    </template>
                  </div>
                  <div class="menu-item-level-2 no-level-3" v-else>
                    <template v-for="(menu2, index2) in menu1.menus">
                      <i
                        class="sn-icon disabled"
                        :class="menu2.icon"
                        data-level="2"
                        @click="chooseMenu2(index1, index2, 3)"
                      ></i>
                      <span class="menu-item1 mgl-5 mgr-20">{{
                        menu2.title
                      }}</span>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @file pages/index.vue
 * @desc this is index.vue
 * @version 0.1.0
 * @author songkexin <songkexin@rongyi.com>
 * @since 2021-04-09
 * @copyright 2021
 */
/* jshint esversion: 6 */
import { mapGetters, mapActions } from "vuex";
// import ctheader from '@@/ctheader';
import ctheader from "../../components/ctheader";
import { apiPost, parse } from "../../modules/utils";
import APIs from "../../modules/APIs";

const getters = mapGetters([]);

const actions = mapActions(["setAlert"]);

const MENU_CHECK = {
  CHECKED_NONE: "sdnm-square_not_selected",
  CHECKED: "sdnm-square_selected",
  CHECKED_PART: "sdnm-square_halfselect",
};

const MENU_TYPE = {
  MENU: 1, // 菜单
  ELEMENT: 2, // 元素
};

function _resetCheckedStatusFromLowToHigh(menu1) {
  let checkedCount = menu1.menus.filter((el) => el.icon === MENU_CHECK.CHECKED)
    .length;
  let uncheckedCount = menu1.menus.filter(
    (el) => el.icon === MENU_CHECK.CHECKED_NONE
  ).length;
  if (checkedCount === menu1.menus.length) {
    return MENU_CHECK.CHECKED;
  } else if (
    uncheckedCount === menu1.menus.length &&
    menu1.icon === MENU_CHECK.CHECKED_NONE
  ) {
    return MENU_CHECK.CHECKED_NONE;
  } else {
    return MENU_CHECK.CHECKED_PART;
  }
}

function menuParse(menus = [], ids) {
  return menus.map((el) => {
    let menu = {
      pid: el.pid,
      id: el.id,
      code: el.code,
      title: el.title,
      genre: el.genre,
      icon: MENU_CHECK.CHECKED_NONE,
      element: !(el.type === MENU_TYPE.MENU),
    };

    if (ids.includes(el.id)) {
      menu.icon = MENU_CHECK.CHECKED;
    }

    if (el.children && el.children.length > 0) {
      menu.lastTwo = !(
        el.children.filter((els) => els.children && els.children.length > 0)
          .length > 0
      );
      menu = parse(menu);
      menu.menus = menuParse(el.children, ids);
      menu.icon = _resetCheckedStatusFromLowToHigh(menu);
    } else {
      menu.menus = [];
      menu.lastTwo = false;
    }
    return menu;
  });
}

/**
 * @module index.vue
 * @desc this is module index.vue
 * @author songkexin <songkexin@rongyi.com>
 * @since 2021-04-09
 * @copyright 2021
 */
export default {
  data() {
    return {
      name: "",
      desc: "",
      type: "",
      menus: [],
      company_menus: [],
      market_menu: [],
      saving: false,
      id: "",
      typeList: [
        {
          id: 1,
          name: "版本权限",
        },
        {
          id: 2,
          name: "功能权限",
        },
      ],
    };
  },
  components: { ctheader },
  computed: {
    ...getters,
  },
  methods: {
    getDetail() {
      let _this = this;
      apiPost({
        url: APIs.VERSION,
        data: {
          action: "version.info",
          id: this.$route.params.id,
        },
        success(res) {
          if (res.code === "0") {
            _this.id = res.data.id;
            _this.name = res.data.name;
            _this.desc = res.data.describe;
            _this.type = res.data.type;
            _this.getMenus(res.data.menu_ids);
          }
        },
        error(res) {},
      });
    },
    /**
     * 获取菜单列表
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-06-06
     */
    getMenus(ids) {
      let _this = this;
      apiPost({
        url: APIs.VERSION,
        data: {
          action: "menu.list",
          genre: 0,
        },
        success(res) {
          if (res.code === "0") {
            // console.log(menuParse(res.data));
            _this.menus = parse(menuParse(res.data.mis_menu, ids));
            _this.company_menus = parse(menuParse(res.data.company_menu, ids));
            _this.market_menu = parse(menuParse(res.data.market_menu, ids));
          }
        },
        error(res) {},
      });
    },
    ...actions,
  },
  mounted() {
    console.log(this.$route);
    this.getDetail();
  },
};
</script>

<style lang="less">
@import url("../../less/common.less");
.limit1 {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
  margin-top: 10px;
}
.new-version {
  .mgl-5 {
    margin-left: 5px;
  }
  .mgr-20 {
    margin-right: 20px;
  }

  &.wrapper .content {
    overflow: auto;
    // padding-bottom: 70px;
  }
  .form .field {
    display: block;
  }
  .orgz-user-role-page {
    overflow-y: auto;

    > .tab-container {
      flex: auto;
      overflow-y: auto;
      position: inherit;
    }
  }

  .btn-box {
    .fs(0);
    .mgt(30px);
  }

  .form.orgz-user {
    > .subject {
      .mgb(0);
    }
    > .field {
      &.half {
        .dib();
      }
      > label {
        &:first-child {
          width: 80px;
          .pdr(16px);
          .fc(14px, #666);
        }
        &.star {
          .pos-r();
          &:before {
            .pos-a(1; 0; 0);
            .dib();
            .whlh(8px, 28px, 28px);
            .ta-c();
          }
        }
      }
      > .sn-radio-v2 {
        .fc(14px, #666);
        .hlh(28px, 28px);
      }
    }
  }

  .right-list {
    max-height: 178px;
    overflow-y: auto;
    .bgc(#f5f5f5);
    .fs(0);
    .pd(8px 8px 0 8px);
    .mgt(12px);
    .mgb(8px);
    min-height: 46px;
    user-select: none;
    > .right-item {
      .dib();
      .hlh(30px, 30px);
      .brdr(4px);
      .fc(12px, #666);
      .bgc(white);
      .pd(0 8px);
      .mgr(8px);
      .mgb(8px);
      .ta-c();
      box-shadow: 0 2px 3px 0 #e1e5ea;
      cursor: default;
      transition: transform 300ms ease, box-shadow 300ms ease;
      &:hover {
        transform: translateX(-1px) translateY(-1px);
        box-shadow: 3px 3px 6px 1px #eaeaea;
        transition: transform 300ms ease, box-shadow 300ms ease;
      }
      > .close {
        .fc(12px, #a0a0a0);
        .mgl(4px);
        cursor: pointer;
      }
    }
  }

  .flip-list-item {
    .dib();
    .mgr(10px);
    transition: all 1s;
  }
  .flip-list-enter-active,
  .flip-list-leave-active {
    transition: transform 1200ms;
  }
  .flip-list-enter,
  .flip-list-leave-to {
    opacity: 0;
    transform: translateY(-30px);
  }

  .flip-list-leave-active {
    position: absolute;
  }

  .flip-list-move {
    transition: transform 1200ms;
  }

  .orgz-right {
    transition: height 1200ms ease;
  }

  .data-title {
    .dib();
    width: 60px;
    .ta-rt();
    .fl-lt();
    line-height: 50px;
    .fc(14px, #666);
  }
  .data-right {
    .mgl(70px);
    .sn-radio-v2 {
      .fc(14px, #666);
      .hlh(28px, 28px);
    }
  }
  .orgz-data {
    overflow: hidden;
    .mgt(20px);
  }

  .role-right-grid {
    .pos-r();
    width: 100%;
    > .system {
      width: 100%;
      > .system-title {
        .hlh(40px, 40px);
        .bgc(#e0e8f4);
        .pdl(16px);
        .brd-b(#d9d9d9);
        .fc(14px, #2e4557);
        &.active {
          border: none;
        }
      }
      .system-content {
        .pd(16px 16px);
        .brd(#d9d9d9);
        &.fold {
        }
      }
    }
  }

  .system-content {
    transition: all 250ms;
  }

  .system-enter,
  .system-leave-to {
    opacity: 0;
  }

  .menu-item {
    line-height: 30px;
    .fs(12px);
  }

  .menu-item1 {
    .fc(14px, #666);
  }
  .menu-item2 {
    .fc(14px, #666);
  }
  .menu-item3 {
    .fc(12px, #666);
  }

  .fa-wrap {
    .dib();
    width: 20px;
    .mgr(10px);
    cursor: pointer;
  }

  .role-right-grid {
    .sn-icon {
      cursor: pointer;
      .fs(14px);
      &.sdnm-square_not_selected {
      }
      &.sdnm-square_selected,
      &.sdnm-square_halfselect {
        .fgc(#4486ff);
      }
      &.disabled {
        .fgc(#dadada);
      }
    }
    &.disabled,
    > .disabled {
      .sn-icon {
        &.sdnm-square_not_selected,
        &.sdnm-square_selected,
        &.sdnm-square_halfselect {
          .fgc(#dadada);
        }
      }
    }
    .role-edit-circle {
      .whlh(24px, 24px, 24px);
      .brdr(100%);
      .bgc(#fff);
      .ta-c();
      .dib();
      .mgr(24px);
    }
  }

  // 替换制表符
  .menu-item-level-1,
  .menu-item-level-2,
  .menu-item-level-3,
  .menu-item-level-4 {
    @linelength: 25px;
    // .hlh(30px, 30px);
    .pos-r();
    &:before {
      content: "";
      .pos-a(1; -2px; 0);
      .dib();
      .wh(@linelength, @linelength);
      .brd-l(#d9d9d9);
      .brd-b(#d9d9d9);
      .mgl(6px);
      .mgt(24px);
    }
    + .level-3 {
      .mgt(-5px);
    }
    &.no-level-3 {
      &:before {
        .dn();
      }
    }
  }

  .menu-item-level-2 {
    .mgl(32px);
  }

  .menu-item-level-3 {
    .mgl(64px);
  }

  .menu-item-level-4 {
    .mgl(96px);
  }

  .menu-item-level-5 {
    .mgl(128px);
  }

  .no-auth {
    .ta-c();
    .pdb(154px);
    .auth-image {
      .mgt(154px);
    }
    .content {
      .text {
        .fc(18px, #666);
      }
      p {
        .fc(14px, #999);
      }
    }
  }
}
</style>
